.promotional {

  &__wrapper {
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__list {
    margin-bottom: 5rem;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:nth-child(even) {
      flex-direction: row-reverse;

      .promotional__item-text {
        margin-right: initial;
        margin-left: 3rem;

        @media (max-width: em(992)) {
          margin-left: initial;
        }
      }

      @media (max-width: em(992)) {
        flex-direction: column;
      }
    }

    &:not(:last-child) {
      margin-bottom: 5rem;

      @media (max-width: em(575)) {
        margin-bottom: 3rem;
      }
    }

    @media (max-width: em(992)) {
      flex-direction: column;
      align-items: initial;
    }
  }

  &__item-text {
    margin-right: 3rem;
    max-width: 50rem;

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 3rem;
      max-width: initial;
    }
  }

  &__item-title {
    margin-bottom: 1.5rem;
    font-weight: 600;
    font-size: fluid-font(2rem, 1.8rem);
    line-height: 150%;
    color: var(--clr-default-700);
  }

  &__item-list {
    list-style: disc;
    padding-left: 2rem;
  }

  &__list-item {
    font-size: fluid-font(1.8rem, 1.6rem);
    line-height: 145%;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__item-image {
    img {
      border: 0.2rem solid var(--clr-default-700);
      border-radius: 2.4rem;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

      @media (max-width: em(575)) {
        border-radius: 1.4rem;
      }
    }

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-left: auto;
      max-width: 70rem;
    }
  }
}

