.info {
  &__table {
    overflow: auto;
    border-radius: 1.5rem;
    background-color: var(--clr-default-600);

    table {
      overflow: hidden;
      border-collapse: collapse;
      border-radius: 1.5rem;
      min-width: 51rem;

      tr {
        td {
          vertical-align: top;
          padding-top: 2rem;
          padding-right: 4rem;
          padding-bottom: 2rem;
          padding-left: 4rem;
          font-size: fluid-font(1.8rem, 1.6rem);
          line-height: 145%;

          &:first-child {
            min-width: 28rem;
            background-color: var(--clr-default-900);

            @media (max-width: em(992)) {
              min-width: 23rem;
            }

            @media (max-width: em(767)) {
              min-width: 18rem;
            }
          }

          &:last-child {
            border-bottom: 0.1rem solid rgba(255, 255, 255, 0.05);;
          }

          @media (max-width: em(767)) {
            padding-left: 2rem;
          }

          @media (max-width: em(575)) {
            padding-top: 1.5rem;
            padding-right: 1.5rem;
            padding-bottom: 1.5rem;
            padding-left: 1.5rem;
          }
        }
      }
    }
  }
}
