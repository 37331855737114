.live {

  &__wrapper {
  }

  &__content-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5rem;

    &::before {
      content: '';
      position: absolute;
      right: -9rem;
      width: 65rem;
      height: 40rem;
      background: radial-gradient(73.86% 235.72% at 31.11% 48.7%, rgba(255, 165, 111, 0.05) 0%, rgba(255, 165, 111, 0) 100%);
      filter: blur(3rem);

      @media (max-width: em(1200)) {
        display: none;
      }
    }

    @media (max-width: em(992)) {
      flex-direction: column;
      align-items: initial;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__content {
    margin-right: 2rem;
    max-width: 70rem;

    @media (max-width: em(1200)) {
      max-width: 55rem;
    }

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 5rem;
      max-width: initial;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    margin-bottom: 3rem;
    p:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__text-bottom {
    p {
      font-weight: 700;
      font-size: fluid-font(1.8rem, 1.6rem);

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }

  &__list {
    list-style: disc;
    padding-left: 2.5rem;
  }

  &__item {
    font-weight: 400;
    font-size: fluid-font(2.4rem, 1.8rem);
    line-height: 145%;
    color: var(--clr-default-700);

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__link {
  }
}

