.resume {

  .container {
    overflow: hidden;
  }

  &__wrapper {
    position: relative;
    z-index: 1;
    margin-left: -7rem;
    padding-right: 7rem;
    padding-bottom: 14.5rem;
    padding-left: 7rem;
    width: calc(100% + 14rem);
    background-image: -webkit-image-set(
      url(../img/resume/bg@2x.png) 2x,
      url(../img/resume/bg@1x.png) 1x
    );
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: em(992)) {
      padding-bottom: 8rem;
    }

    @media (max-width: em(575)) {
      padding-bottom: 5rem;
    }
  }

  &__content-wrapper {
    margin-bottom: 5rem;

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__content {
    &:not(:last-child) {
      margin-bottom: 10rem;

      @media (max-width: em(992)) {
        margin-bottom: 3rem;
      }
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    p:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__link {
  }
}

