.app {

  .container {
    overflow: hidden;
  }

  &__wrapper {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    padding-top: 19.5rem;
    padding-bottom: 19.5rem;
    max-width: 59.5rem;

    &::before, &::after {
      content: '';
      position: absolute;
      width: 58.4rem;
      height: 79.5rem;
      background-size: contain;
      background-repeat: no-repeat;

      @media (max-width: em(1360)) {
        display: none;
      }
    }

    &::before {
      left: -46.5rem;
      top: -8rem;
      background-image: -webkit-image-set(
        url(../img/app/app-left@2x.png) 2x,
        url(../img/app/app-left@1x.png) 1x
      );
    }

    &::after {
      right: -48rem;
      top: -6rem;
      background-image: -webkit-image-set(
        url(../img/app/app-right@2x.png) 2x,
        url(../img/app/app-right@1x.png) 1x
      );
    }

    @media (max-width: em(1360)) {
      padding-top: 10rem;
      padding-bottom: 10rem;
      max-width: initial;
    }

    @media (max-width: em(992)) {
      padding-top: 8rem;
      padding-bottom: 8rem;
    }

    @media (max-width: em(575)) {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
    text-align: center;
  }

  &__text {
    text-align: center;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}

