.vip {
  .container {
    position: relative;
    overflow: hidden;
    padding-top: 14rem;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #292526 0%, rgba(41, 37, 38, 0) 100%);
      transform: rotate(180deg);
      pointer-events: none;

      @media (max-width: em(1390)) {
        display: none;
      }
    }

    @media (max-width: em(1390)) {
      overflow: initial;
      padding-top: 10rem;
      padding-bottom: 10rem;
    }

    @media (max-width: em(992)) {
      padding-top: 8rem;
      padding-bottom: 8rem;
    }

    @media (max-width: em(575)) {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-left: -7rem;
    padding-right: 7rem;
    padding-bottom: 10rem;
    padding-left: 7rem;
    width: calc(100% + 14rem);
    background-image: -webkit-image-set(
      url(../img/vip/bg@2x.png) 2x,
      url(../img/vip/bg@1x.png) 1x
    );
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: em(1390)) {
      margin-left: -1.5rem;
      padding-right: 1.5rem;
      padding-bottom: initial;
      padding-left: 1.5rem;
      width: calc(100% + 3rem);
      background-image: initial;
    }

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__content {
    z-index: 2;
    margin-right: 3rem;
    max-width: 80rem;

    @media (max-width: em(1280)) {
      max-width: 65rem;
    }

    @media (max-width: em(1100)) {
      max-width: 50rem;
    }

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 5rem;
      max-width: initial !important;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    margin-bottom: 3rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__promotional {
  }

  &__promotional-title {
    margin-bottom: 3rem;
    font-weight: 600;
    font-size: fluid-font(2rem, 1.8rem);
    text-transform: uppercase;
  }

  &__list {
    list-style: disc;
    padding-left: 2rem;
  }

  &__item {
    line-height: 145%;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__image {
    position: absolute;
    right: -1rem;
    bottom: -1.2rem;
    width: 45.5rem;
    height: 59rem;

    .image__text {
      width: 48rem;
      transform: translate(-50%, -50%) rotate(-45deg);

      &::before {
        width: 35rem;
        height: 26rem;
        filter: blur(10rem);
        transform: translate(-50%, -50%) rotate(-116deg);
      }

      @media (max-width: em(1390)) {
        width: 35rem;
        transform: translate(-50%, -50%) rotate(0);
      }

      @media (max-width: em(575)) {
        width: 24rem;
        font-size: 3rem;
      }
    }

    img {
      filter: initial;
    }

    @media (max-width: em(1390)) {
      position: relative;
      right: initial;
      bottom: initial;
      width: initial;
      height: initial;
      height: max-content;
      max-width: 45.5rem;
    }

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-left: auto;
    }
  }
}
