.slot {

  &__wrapper {
  }

  &__title {
    margin-bottom: 2rem;

  }

  &__text {
    margin-bottom: 3rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__table {
    table {
      border-collapse: collapse;
      min-width: 100%;

      thead {
        th {
          &:first-child {
            min-width: initial;
            max-width: 38rem;
          }
        }
      }

      tbody {
        tr {
          &:not(:last-child) {
            border-bottom: 0.1rem solid rgba(255, 255, 255, 0.11);
          }
        }

        td {
          &:first-child {
            color: var(--clr-default-100);
          }
        }
      }
    }
  }
}

