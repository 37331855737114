// <МИКСИНЫ>==================================================================
@import "./settings/mixins";

// </МИКСИНЫ>=================================================================

// ФУНКЦИИ====================================================================
@import "./settings/functions";

// </ФУНКЦИИ>=================================================================

// <Подключаем шрифты>========================================================
@import "./settings/fonts";

// </Подключаем шрифты>=======================================================

// <ОБНУЛЕНИЕ>================================================================
@import "./settings/normalize";

// </ОБНУЛЕНИЕ>===============================================================

// <ПЕРЕМЕННЫЕ>===============================================================
$containerWidth: 136rem;

:root {
  // base
  --font-family: "Segoe UI", sans-serif;
  --font-weight: 400;
  --content-width: 122rem;
  --container-offset: 7rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.3s;

  // colors
  --clr-default-100: #fff;
  --clr-default-200: #fffaf3;
  --clr-default-400: #292526;
  --clr-default-500: #1f1a1b;
  --clr-default-600: #161616;
  --clr-default-700: #fec882;
  --clr-default-800: #94897a;
  --clr-default-900: #000;
  --clr-primary-200: rgba(255, 250, 243, 0.15);
  --clr-primary-300: rgba(255, 255, 255, 0.66);
  --clr-primary-400: linear-gradient(180deg, #fec882 0%, #f98745 100%);
  --clr-primary-500: #E36D22;;
  --clr-primary-600: #8f8f8f;
  --clr-primary-800: #161616;
  --clr-primary-900: #111111;
}

// </ПЕРЕМЕННЫЕ>==============================================================

// <ОБОЛОЧКИ>================================================================
body {
  font-weight: var(--font-weight);
  font-size: 1.8rem;
  color: var(--clr-default-100);
  background-color: var(--clr-default-500);

  &.lock {
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    overscroll-behavior: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    background-color: rgb(0 0 0 / 50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--transition), visibility var(--transition);
  }

  &[data-overlay="true"]::before {
    opacity: 1;
    visibility: visible;
    transition: opacity var(--transition), visibility var(--transition);
  }
}

main {
  flex-grow: 1;
}

// </ОБОЛОЧКИ>===============================================================

// <СЕТКА>===================================================================
.container {
  margin-right: auto;
  margin-left: auto;
  border-right: 0.1rem solid rgba(255, 255, 255, 0.15);
  border-left: 0.1rem solid rgba(255, 255, 255, 0.15);
  padding-right: var(--container-offset);
  padding-left: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  background-color: var(--clr-default-400);

  &--top {
    border-top: 0.1rem solid rgba(255, 255, 255, 0.15);
    border-radius: 1.5rem 1.5rem 0 0;
  }

  &--bottom {
    border-bottom: 0.1rem solid rgba(255, 255, 255, 0.15);
    border-radius: 0 0 1.5rem 1.5rem;
  }

  @media (max-width: em(1390)) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  @media (max-width: em(1360)) {
    border-top: initial;
    border-radius: initial;
  }
}

// Components - UI
@import "./ui";

// Components - UI

// Components - UI
@import "./components/ui/menu";
@import "./components/ui/logo";
@import "./components/ui/burger";

// Components - UI

// Components - Common
@import "./components/common/header";
@import "./components/common/footer";

// Components - Common

// Components - Pages
@import "./components/indexPage/hero";
@import "./components/indexPage/info";
@import "./components/indexPage/benefits";
@import "./components/indexPage/register";
@import "./components/indexPage/app";
@import "./components/indexPage/mobile";
@import "./components/indexPage/promotional";
@import "./components/indexPage/vip";
@import "./components/indexPage/tournament";
@import "./components/indexPage/slot";
@import "./components/indexPage/games";
@import "./components/indexPage/live";
@import "./components/indexPage/license";
@import "./components/common/faq";
@import "./components/indexPage/payment";
@import "./components/indexPage/resume";

// Components - Pages

// </СЕТКА>==================================================================

// <ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>===========================

// <ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>===========================
