.hero {
  &__wrapper {
    padding-top: 7rem;

    @media (max-width: em(992)) {
      padding-top: 5rem;
    }

    @media (max-width: em(767)) {
      padding-top: 3rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
    font-size: fluid-font(5.2rem, 2.8rem);
    text-align: center;
  }

  &__text {
    margin-bottom: 4rem;
    text-align: center;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }

    @media (max-width: em(767)) {
      margin-bottom: 3rem;
    }
  }

  &__image {
    position: relative;
    margin-right: auto;
    margin-bottom: 3rem;
    margin-left: auto;
    max-width: 122rem;

    &--text {
      max-width: 36rem;
    }
  }

  &__link {
    min-width: 100%;

    @media (max-width: em(992)) {
      min-width: 100%;
    }
  }
}

