.payment {

  &__wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 10rem;

    @media (max-width: em(992)) {
      flex-direction: column;
      align-items: initial;
      padding-bottom: 8rem;
    }

    @media (max-width: em(575)) {
      padding-bottom: initial;
    }
  }

  &__content {
    margin-right: 3rem;
    max-width: 60rem;

    @media (max-width: em(1200)) {
      max-width: 55rem;
    }

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 5rem;
      max-width: initial;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    margin-bottom: 3rem;

    p:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__list {
    list-style: disc;
    padding-left: 2.5rem;

    @media (max-width: em(575)) {
      padding-left: 2rem;
    }
  }

  &__item {
    font-weight: 600;
    font-size: fluid-font(2rem, 1.8rem);
    line-height: 150%;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__image {
    max-width: 59rem;

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-left: auto;
    }
  }
}
