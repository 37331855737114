.license {

  &__text p {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
  }
}
