.faq {

  &__wrapper {
  }

  &__title {
    margin-bottom: 3rem;
  }

  &__list {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 1fr 1fr;

    @media (max-width: em(992)) {
      display: block;
      grid-gap: initial;
    }
  }

  &__item {
    border: 0.2rem solid var(--clr-primary-200);
    border-radius: 1.5rem;
    padding: 4rem;
    background-color: var(--clr-default-600);

    &:nth-child(3) {
      grid-column: 1 / 3;
    }

    @media (max-width: em(992)) {
      &:not(:last-child) {
        margin-bottom: 3rem;
      }
    }

    @media (max-width: em(767)) {
      padding: 3rem;
    }

    @media (max-width: em(575)) {
      padding: 2rem;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }

  &__item-title {
    margin-bottom: 2rem;
    font-weight: 600;
    font-size: fluid-font(2.4rem, 1.8rem);
    line-height: 150%;
  }

  &__text {
  }
}
