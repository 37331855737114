.games {

  .container {
    overflow: hidden;
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 3rem;

    @media (max-width: em(1280)) {
      padding-bottom: initial;
    }

    @media (max-width: em(992)) {
      flex-direction: column-reverse;
    }
  }

  &__image {
    position: absolute;
    left: -14rem;
    width: 66rem;
    height: 50rem;
    max-width: 66rem;

    @media (max-width: em(1280)) {
      position: initial;
      margin-right: 3rem;
      width: initial;
      height: initial;
      max-width: 66rem;
    }

    @media (max-width: em(992)) {
      margin-right: initial;
    }
  }

  &__content {
    margin-left: auto;
    max-width: 65rem;

    @media (max-width: em(1280)) {
      max-width: 55rem;
    }

    @media (max-width: em(992)) {
      margin-bottom: 5rem;
      margin-left: initial;
      max-width: initial;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__list {
    list-style: disc;
    padding-left: 2rem;
  }

  &__item {
    font-size: fluid-font(1.8rem, 1.6rem);
    line-height: 145%;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}

